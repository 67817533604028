















































import { MarketKeyInfo } from '@/blockchainHandlers/ido-contract-interface'
import { PoolStore } from '@/modules/ido/stores/pool-store'
import { Observer } from 'mobx-vue'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Observer
@Component({
  components: {
    PoolCover: () => import('@/components/images/pool-cover.vue'),
  },
})
export default class HoverImage extends Vue {
  @Prop({ required: true }) keyInfo!: MarketKeyInfo
  @Prop({ required: true }) poolStore!: PoolStore
  @Prop({ default: false }) showId!: boolean
  @Prop({ default: false }) showChain!: boolean
  @Prop({ default: false }) showSoldOut!: boolean
  @Prop({ default: true }) showInfo!: boolean
  @Prop({ default: true }) showFrame!: boolean
  @Prop({ default: false }) isXSmal!: boolean

  get poolUrl() {
    return this.poolStore ? this.poolStore.coverUrl : ''
  }
}
